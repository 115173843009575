import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Checkbox, Button, notification, Spin } from "antd";
import { getApitoken, getCustomerDetails } from "../module/landingScreen";
import { paymentRequest } from "../module/payment";
import { createOrder } from "../module/createOrder";
import { v4 as uuidv4 } from 'uuid';

function CustomerDetails({ cartItems, totalPrice, orderRequest }) {
  const generateUniqueId = () => uuidv4();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [themeUrl, setThemeUrl] = useState([]);
  const [apiToken, setApiToken] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [redirectURL, setRedirectURL] = useState("");
  const [loading, setLoading] = useState(false);
  const cost_center = localStorage.getItem("costCenter");

  const showModal = () => {
    if (cartItems.length === 0) {
      notification.warning({
        message: "Empty Cart",
        description:
          "Please add items to the cart before proceeding to checkout.",
      });
    } else {
      setIsModalVisible(true);
    }
  };

  const getAPIToken = async () => {
    const res = await getApitoken();
    if (res.status === 200) {
      setApiToken(res.data.message.data.api_token);
      setThemeUrl(res.data.message.data.url);
    } else {
      console.log("Error in getting the API Token");
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  const handleCustomerDetails = async (themeUrl) => {
    const res = await getCustomerDetails(themeUrl);
    if (res.status === 200) {
      setCustomerDetails(res.data.message);
    } else {
      //console.log("Error in getting the API Customer Details");
    }
  };

  useEffect(() => {
    if (themeUrl) {
      handleCustomerDetails(themeUrl);
    }
  }, [themeUrl]);

  const generatePrefixedId = (prefix) => `${prefix}${generateUniqueId().slice(0, 8)}`; // Generate a prefixed ID


  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const currentDate = new Date();

      localStorage.setItem("customerDetails", JSON.stringify(values));
      localStorage.setItem("totalPrice", totalPrice);

      const itemsWithIds = cartItems.flatMap((item) => {
        // Generate IDs for child items first
        const attributes = item.selectedAttributes
          ? Object.values(item.selectedAttributes).flatMap((optionItemArray) =>
              optionItemArray
                ? optionItemArray
                    .filter((option) => option !== null)
                    .map((option) => ({
                      item_code: option.item,
                      custom_parent_item: item.id,
                      custom_is_attribute_item: 1,
                      item_name: option.item_name || option.item,
                      allow_zero_evaluation_rate:
                        parseFloat(option.price) === 0 ? 1 : 0,
                      rate: parseFloat(option.price) || 0,
                      qty: item.quantity,
                      estimated_time: item.estimated_time,
                      tax: option.item_tax,
                      custom_ca_id: generatePrefixedId("A"), // Attribute ID
                    }))
                : []
            )
          : [];

        const combo_items = item.selectedComboItems
          ? Object.values(item.selectedComboItems).flatMap((comboItemArray) =>
              comboItemArray
                ? comboItemArray
                    .filter((combo) => combo !== null)
                    .map((combo) => ({
                      item_code: combo.item,
                      custom_parent_item: item.id,
                      custom_is_combo_item: 1,
                      item_name: combo.item_name || combo.item,
                      allow_zero_evaluation_rate:
                        parseFloat(combo.price) === 0 ? 1 : 0,
                      rate: parseFloat(combo.price) || 0,
                      qty: item.quantity,
                      estimated_time: item.estimated_time,
                      tax: combo.item_tax,
                      custom_ca_id: generatePrefixedId("C"), // Combo ID
                    }))
                : []
            )
          : [];

        // Determine parent ID based on child items
        const childItems = [...attributes, ...combo_items];
        const parentPrefix = childItems.length
          ? childItems[0].custom_ca_id.charAt(0) // Prefix from the first child item ID
          : "P"; // Default prefix if no child items
          
        const parentItemId =
          childItems.length > 0
            ? generatePrefixedId(parentPrefix) // Generate new ID with parent's prefix
            : generatePrefixedId(parentPrefix); // Default prefix

        // Create base item with determined parent ID
        const baseItem = {
          item_code: item.id,
          item_name: item.name,
          rate: item.product_price,
          qty: item.quantity,
          ordered_price: item.product_price,
          estimated_time: item.estimated_time,
          tax: item.tax,
          custom_ca_id: parentItemId,  
        };

        // Update child items with parent's ID
        return [
          baseItem,
          ...attributes.map((attr) => ({
            ...attr,
            custom_parent_item: item.id,
            custom_ca_id: parentItemId, // Ensure child items also get the parent ID
          })),
          ...combo_items.map((combo) => ({
            ...combo,
            custom_parent_item: item.id,
            custom_ca_id: parentItemId, // Ensure child items also get the parent ID
          })),
        ];
      });

      const payload1 = {
        order_list: {
          source: "WEB",
          hub_manager: "hssozi@erpchampions.org",
          transaction_date: currentDate
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          delivery_date: currentDate.toISOString().split("T")[0],
          items: itemsWithIds,
          mode_of_payment: "Cash",
          mpesa_No: [],
          tax: [],
          type: "Takeaway",
          email: values.email,
          mobile: values.phoneNumber,
          park_order: 0,
          customer_name: values.customerName,
          cost_center: cost_center,
          order_request: orderRequest,
        },
      };

      const payload = {
        payment_list: {
          auth_token_url: customerDetails.authtoken_url,
          client_id: customerDetails.client_id,
          client_secret: customerDetails.client_secret,
          base_payment_url: customerDetails.base_payment_url,
          customer_email: values.email,
          customer_name: values.customerName,
          customer_phone: values.phoneNumber,
          country_code: "GB",
          request_lang: "en-GB",
          amount: totalPrice * 100,
          customer_trans: "payment description",
          checkout_url: customerDetails.checkout_url,
          source_code: customerDetails.source_code,
          merchant_id: customerDetails.merchant_id,
          isv_percentage: customerDetails.isv_percentage_web,
        },
      };

      const res = await createOrder(payload1);
      if (res.status === 200) {
        const order_id = res.data.message.sales_order.name;
        localStorage.setItem("OrderID", order_id);
        localStorage.setItem(
          "TransactionDate",
          currentDate.toISOString().slice(0, 19).replace("T", " ")
        );
        notification.success({ message: "Order Created Successfully" });
        const redirectURl = await paymentRequest(payload, apiToken);
        setRedirectURL(redirectURl.data.message);

        for (const key in localStorage) {
          if (
            key.startsWith("attributes_") ||
            key.startsWith("combo_items_") ||
            key.startsWith("selectedAttributes_") ||
            key.startsWith("selectedComboItems_")
          ) {
            localStorage.removeItem(key);
          }
        }

        if (redirectURl.data.message) {
          window.location.href = redirectURl.data.message;
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("Checkout Error: ", error);
      setLoading(false);
      notification.error({
        message: "Checkout Error",
        description: error.message,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please input a valid email address!");
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^(0|\+44)\d{10}$/;
    if (!value) {
      return Promise.resolve();
    } else if (!value.startsWith("0") && !value.startsWith("+44")) {
      return Promise.reject("Please add 0 or +44 to the phone number!");
    } else if (!phoneRegex.test(value)) {
      return Promise.reject("Please input a valid UK phone number!");
    }
    return Promise.resolve();
  };

  const handlePhoneNumberChange = (e) => {
    let { value } = e.target;

    if (!/^[0+\d]+$/.test(value)) {
      value = "";
    }
    if (value.startsWith("+44")) {
      value = value.slice(0, 13);
    } else if (value.startsWith("0")) {
      value = value.slice(0, 11);
    }

    form.setFieldsValue({ phoneNumber: value });
  };

  return (
    <>
      <Button type="primary" onClick={showModal} className="w-100 checkout-btn">
        Proceed To CheckOut
      </Button>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ width: "50%" }}
      >
        <Form form={form} layout="vertical">
          <h3>Customer Details</h3>
          <Form.Item
            name="customerName"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { validator: validateEmail },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: "Please input your phone number!" },
              { validator: validatePhoneNumber },
            ]}
          >
            <Input
              placeholder="Enter phone number"
              onChange={handlePhoneNumberChange}
            />
          </Form.Item>
          <Form.Item name="consent" valuePropName="checked">
            <Checkbox>
              I consent to the collection and storage of my personal data, and
              to receive marketing emails.
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="iagree"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "You must agree to the Terms and Conditions!",
              },
            ]}
          >
            <Checkbox className="agree_links">
              I agree to the &nbsp;
              <a
                href="https://www.kleocloud.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , &nbsp;
              <a
                href="https://www.kleocloud.com/terms-and-condition"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>
            </Checkbox>
          </Form.Item>
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            className="btn-primary submit-btn"
            disabled={loading}
          >
            {loading ? (
              <Spin size="large" className="loading-spinner" />
            ) : (
              "Proceed to Pay"
            )}
          </Button>
        </Form>
      </Modal>
      {/* {loading && <Spin />} */}
    </>
  );
}

export default CustomerDetails;
